import _ from "lodash";
/**
 * Get the top window object. Fallback to current window if it's not accessible.
 * Possible error: `DOMException: Blocked a frame with origin site..`
 */
export const getWindowTop = () => {
    let windowRef;
    try {
        // Attempt to reference any property under window.top to trigger the expected exception if security restrictions are present.
        windowRef = window.top._anyProp || window.top;
    }
    catch (error) {
        console.warn('Failed to get top window object. Fallback to current window.');
        windowRef = window;
    }
    return windowRef;
};
const windowRef = getWindowTop();
/**
 * Helper for supporting setTimeout with async-await way to flatten things out.
 *
 * For example,
 *
 * setTimeout(() => {
 *   foo();
 *   bar();
 * }, 300);
 *
 * Could be:
 *
 * await waitFor(300);
 * foo();
 * bar();
 */
export const waitFor = (duration) => {
    return new Promise((resolve) => setTimeout(resolve, duration));
};
const nextTickDelay = 50;
/**
 * Wait until next tick for stencil DOM updates in queue.
 * This guarantees the DOM elements are refreshed accordingly after component props are changed.
 */
export const waitForNextTick = () => waitFor(nextTickDelay);
/**
 * Set object prop which can only be directly accessed to by context[prop], but will be ignored
 * while enumeration via such as _.each, _.isEqual, etc...
 */
export const setInnumerableProp = (context, prop, value) => {
    Object.defineProperty(context, prop, { enumerable: false, writable: true, value });
};
/**
 * Sync up between component state and a url query param it's hooked with.
 *
 * For example, while url is `http://host:1234?foo=bar`, and do
 *
 *   mapStateToQueryParam(component, 'editId', 'foo');
 *
 * It will set component.editId = 'bar' as the initial value.
 * And the further component.editId changes will also be persisted back to url query param `foo`.
 */
export const mapStateToQueryParam = (component, state, param) => {
    component[state] = getUrlQueryParams(param);
    observeOn(component, state, (newValue) => setUrlQueryParam(param, newValue));
};
const getOriginalSetter = (object, prop) => {
    const origProps = Object.getOwnPropertyDescriptor(object, prop) || Object.getOwnPropertyDescriptor(object.constructor.prototype, prop);
    return _.isFunction(origProps && origProps.set) ? origProps.set : null;
};
/**
 * A simple observer triggers the passed handler when object property is changed.
 */
export const observeOn = (object, prop, handler) => {
    const originalSetter = getOriginalSetter(object, prop);
    let propValue = object[prop];
    Object.defineProperty(object, prop, {
        set: (value, ...args) => {
            // For composition on top of the existing setter.
            if (originalSetter) {
                originalSetter.call(object, value, ...args);
            }
            propValue = value;
            if (_.isFunction(handler)) {
                handler(value);
            }
        },
        get: () => propValue,
    });
};
export const getUrlQueryParams = (key) => {
    const search = windowRef.location.search;
    let params;
    params = URLSearchParams
        ? new URLSearchParams(windowRef.location.search).getAll(key)
        : Array.from(new RegExp(`[\?&]${key}=([^&#]*)`).exec(search)).map(decodeURIComponent);
    params = params.length === 1 ? _.first(params) : params;
    return _.isEmpty(params) ? undefined : params;
};
/* Recursively searching nested objects with matched value. */
export const deepIncludes = (object, term = '') => {
    const filter = term.toLowerCase();
    return _.some(object, (value) => {
        let match = false;
        if (_.isPlainObject(value)) {
            match = deepIncludes(value, filter);
        }
        else {
            match = _([value])
                .flatten()
                .some((prop) => _.isString(prop) ? _.toLower(prop).includes(filter) : deepIncludes(prop, filter));
        }
        return match;
    });
};
export const transformValuesForUrlParams = (value) => {
    return _([value])
        .flatten()
        .reject((item) => _.isNil(item) || item === '')
        .map(String)
        .value();
};
export const setUrlQueryParam = (key, value) => {
    const baseUrl = getBaseUrl();
    const queryList = windowRef.location.search;
    const valueArray = transformValuesForUrlParams(value);
    const hasValue = !_.isEmpty(valueArray);
    const newParam = hasValue ? valueArray.map((item) => {
        let queryParamValue = item;
        if (!isEncoded(queryParamValue)) {
            queryParamValue = encodeURIComponent(queryParamValue);
        }
        return `${key}=${queryParamValue}`;
    }).join('&') : '';
    let params = `?${newParam}`;
    if (queryList) {
        if (hasValue) {
            const updateRegex = new RegExp(`([\?&])${key}[^&]*`, 'g');
            const matches = queryList.match(updateRegex);
            const paramExist = matches !== null;
            // Update or append params
            params = paramExist ? queryList.replace(updateRegex, `$1${newParam}`) : `${queryList}&${newParam}`;
            // Remove duplicate params.
            if (matches && matches.length > 1) {
                while (params.indexOf(newParam) !== params.lastIndexOf(newParam)) {
                    params = params.replace(`&${newParam}`, '');
                }
            }
        }
        else {
            // Remove params
            const removeRegex = new RegExp(`([\?&])${key}=[^&;]+[&;]?`);
            let result = queryList;
            while (result.match(removeRegex)) {
                result = result.replace(removeRegex, '$1');
            }
            params = result.replace(/[&;]$/, '');
        }
    }
    const historyState = (baseUrl + params).replace(/[&|?]*$/, '');
    replaceHistoryState(historyState);
};
/** For `trackHistoryStateChange` to monitor URL changes */
const historyChangeCallback = [];
let currentWindowUrl = windowRef.location.href;
let proxyApplied = false;
export const trackHistoryStateChange = (callback) => {
    const proxyMethod = (target, thisArg, argArray) => {
        const result = target.apply(thisArg, argArray);
        if (currentWindowUrl !== windowRef.location.href) {
            currentWindowUrl = windowRef.location.href;
            _.each(historyChangeCallback, (changeCallback) => changeCallback.call(changeCallback, currentWindowUrl));
        }
        return result;
    };
    historyChangeCallback.push(callback);
    if (!proxyApplied) {
        windowRef.history.pushState = new Proxy(windowRef.history.pushState, { apply: proxyMethod });
        windowRef.history.replaceState = new Proxy(windowRef.history.replaceState, { apply: proxyMethod });
        proxyApplied = true;
    }
};
export const safeParseJSON = (jsonString, errorMessage) => {
    let result;
    if (_.isString(jsonString)) {
        try {
            result = JSON.parse(jsonString);
        }
        catch (e) {
            if (errorMessage) {
                console.error(`${errorMessage}. Input: ${jsonString}`);
            }
        }
    }
    return result !== null && result !== void 0 ? result : jsonString;
};
export const getBaseUrl = () => `${windowRef.location.protocol}//${windowRef.location.host}${windowRef.location.pathname}`;
export const getURI = () => getWindowTop().document.location.href;
export const replaceHistoryState = (state) => windowRef.history.replaceState({}, '', state);
export const isEncoded = (query) => decodeURIComponent(query) !== query;
export const isObjectArray = (arr) => _.isArray(arr) && arr.length > 0 && arr.every(_.isObject);
